import { useEffect, useState, useContext, createContext, useRef, ReactNode } from 'react';
import { ReactComponent as NoodleLogo } from 'assets/icons/ai_noodle_icon.svg';
import { Button, Menu } from 'antd';
import { CloseOutlined, MoreOutlined, SyncOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { Chat } from 'pages/Agents/components/Chat/Chat';
import { AgentsContext, useAgentsContext } from 'pages/Agents/context/AgentsContext';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import "./styles.css";
import AuthContext from 'context/auth/context';
import { SelectedFilters } from 'hooks/useFunctionalAreaFilters';
import { useHistory } from 'react-router-dom';

type AIInquiryChatContextType = {
    isChatOpen: boolean;
    setIsChatOpen: (isChatOpen: boolean) => void;
    defaultQuestion: string | undefined;
    setDefaultQuestion: (defaultQuestion: string | undefined) => void;
    userSession: {
        username: string;
        idToken: string;
        accessToken: string;
        refreshToken: string;
    } | undefined;
    onClose: () => void;
    onDefaultQuestionClick: (question: string) => void;
    pageFilters: SelectedFilters | undefined;
    setPageFilters: (filter: SelectedFilters) => void;
};

export const AIInquiryChatContext = createContext<AIInquiryChatContextType>({
    isChatOpen: false,
    setIsChatOpen: (isChatOpen: boolean) => { },
    defaultQuestion: undefined,
    setDefaultQuestion: (defaultQuestion: string | undefined) => { },
    userSession: undefined,
    onClose: () => {},
    onDefaultQuestionClick: (question: string) => {},
    pageFilters: undefined,
    setPageFilters: () => {}
});

export const useAIInquiryChat = () => {
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [defaultQuestion, setDefaultQuestion] = useState<string | undefined>();
    const { userSession } = useContext(AuthContext);
    const [pageFilters, setPageFilters] = useState<SelectedFilters | undefined>();

    const onClose = () => {
        setIsChatOpen(false);
        setDefaultQuestion(undefined);
    };

    const onDefaultQuestionClick = (question: string) => {
        setDefaultQuestion(question);
        setIsChatOpen(true);
    }

    return {
        isChatOpen,
        setIsChatOpen,
        defaultQuestion,
        setDefaultQuestion,
        userSession,
        onClose,
        onDefaultQuestionClick,
        pageFilters,
        setPageFilters
    };
};

export const AIInquiryProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    return (
        <AIInquiryChatContext.Provider value={useAIInquiryChat()}>
            {children}
        </AIInquiryChatContext.Provider>
    );
}


export const AIInquiryChatWindow = (): JSX.Element => {
    const { isChatOpen, onClose, userSession, defaultQuestion } = useContext(AIInquiryChatContext);
    const { setClearChat, agent } = useAgentsContext();
    const ref = useRef<any>(null);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)
                && !event.target.closest('li.ant-dropdown-menu-item')) {
                onClose();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onClose]);

    const menu = (
        <Menu>
            {setClearChat && (
                <Menu.Item onClick={() => setClearChat(true)} className='pendo_clear_ai_chat'>
                    <SyncOutlined /> Refresh Chat
                </Menu.Item>
            )}
        </Menu>
    );

    return (
        <div
            className="ai-chat-window-container"
            style={!isChatOpen ? { visibility: "hidden", display: "none" } : {}}
            ref={ref}
        >
            <div className="ai-chat-window-header">
                <NoodleLogo width={24} height={24} />
                <span>Noodle AI</span>

                <Dropdown overlay={menu} trigger={['click']}>
                    <Button
                        icon={<MoreOutlined />}
                        shape="circle"
                        size="small"
                        type="text"
                        style={{ marginLeft: "auto" }}
                    />
                </Dropdown>

                <Button
                    icon={<CloseOutlined />}
                    shape="circle"
                    size="small"
                    onClick={() => onClose()}
                    style={{ marginLeft: "10px" }}
                />
            </div>
            <div style={{ height: "100%", width: "100%", overflow: "hidden", borderBottomLeftRadius: "0.6rem", borderBottomRightRadius: "0.6rem" }}>
                {(!agent || agent?.loading) && <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>Loading...</div>}
                {agent && !agent.loading &&
                    <Chat isGlobalChat defaultQuestion={defaultQuestion} />
                }
            </div>
        </div>
    );
};

export default function AIInquiryChat(): JSX.Element {
    const { isChatOpen, setIsChatOpen, userSession } = useContext(AIInquiryChatContext);
    const [mounted, setMounted] = useState(false);
    const [clearChat, setClearChat] = useState(false);
    const [agent, setAgent] = useState();
    const history = useHistory();
    const { data, loading } = useQuery(GET_AGENTS, {
        variables: {
            pagination: {
                page_size: 1,
                page_number: 1,
                filters: [
                    {
                        property: "identifier",
                        value: history.location.pathname === '/wiki'
                            ? process.env.REACT_APP_NMANAGE_WIKI_AGENT_IDENTIFIER
                            : process.env.REACT_APP_NMANAGE_AGENT_IDENTIFIER,
                        rel_operator: "like",
                    }
                ]
            }
        },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        setTimeout(() => setMounted(true), 3000);
    }, []);

    useEffect(() => {
        if (data && data.getAgents && data.getAgents.length > 0) {
            const tempAgent = data.getAgents[0];
            tempAgent.loading = loading;
            setAgent(tempAgent);
        }
    }, [data, loading]);

    return (
        <AgentsContext.Provider value={{ agent, session: userSession, clearChat, setClearChat }}>
            <AIInquiryChatWindow />
            <div
                className="ai-chat-init-container pendo_open_ai_chat"
                style={isChatOpen || !mounted ? { backgroundColor: "#e7e7e7", boxShadow: "unset", borderRadius: "50%" } : {}}
                onClick={() => mounted ? setIsChatOpen(!isChatOpen) : null}
            >
                {!isChatOpen && mounted && <NoodleLogo width={52} height={52} />}
            </div>
        </AgentsContext.Provider>
    );
}

export const GET_AGENTS = gql`
query GetAgents($pagination: PaginatedRequestInput) {
    getAgents(pagination: $pagination) {
        id
        name
        identifier
        description
        prompt
        temperature
        top_p
        type
        input_variables
        provider {
            id
            name
            encoding_model
            api_key
        }
        toolkits {
            id
            name
            description
            api_key
            tools {
                id
                name
                func
                description
            }
        }
        vector_stores {
            id
            name
            index_name
            type
            top_k
            length_per_result
            files
            is_retriever_tool
        }
        tracers {
            id
            project_name
            type
            agent_id
            public_key
            secret_key
            host
            api_key
            tags
        }
    }
}`;
import React from 'react';

export interface TimePeriodFilters {
  [key: string]: any;
  DailyDialsTalktime: string;
  HoursToFirstCall: string;
  SevenDayEngagementRate: string;
  SevenDayEngagedToStartedAppRate: string;
  EnrollmentFunnelPacing: string;
  CoachResolutionTimes?: string;
  StudentsAwaitingCoachOutreach?: string;
  StudentAcademicRiskIndicators?: string;
  CoachReportedStudentRisk?: string;
  CoachResponseTimes?: string;
  PaidMediaSpendPacing?: string;
  ProspectPacing?: string;
  CostPerProspect?: string;
}

type setNewTimePeriodFilter = (reportKey: string, newTimeValue: string) => void;

export type FiltersContextType = {
  timePeriodFilters: TimePeriodFilters;
  sortByFilters: TimePeriodFilters;
  termsFilter: number;
  keyDatesFilter: IKeyDatesGoalsFilters;
  goalsFilter: IKeyDatesGoalsFilters;
  goalGroupKeyDatesFilter: IGoalGroupKeyDatesGoalsFilters;
  goalGroupGoalsFilter: IGoalGroupKeyDatesGoalsFilters;
  updateTimePeriodFilters: setNewTimePeriodFilter;
  updateSortByFilters: setNewTimePeriodFilter;
  updateTermFilter: (value: number) => void;
  updateKeyDatesFilter: (key: string, value: string[] | number[] | number | boolean) => void;
  updateGoalsFilter: (key: string, value: string[] | number[] | number | boolean) => void;
  updateGoalGroupKeyDatesFilter: (key: string, value: string[] | number[] | number | boolean) => void;
  updateGoalGroupGoalsFilter: (key: string, value: string[] | number[] | number | boolean) => void;
};

export interface IKeyDatesGoalsFilters {
  [key: string]: any;
  onlyDataICanEdit: boolean;
  onlyMyPrograms: boolean;
  status: string[];
  partners: number[];
  programs: number[];
  isEditing: boolean;
  term: number;
}

export interface IGoalGroupKeyDatesGoalsFilters {
  [key: string]: any;
  onlyDataICanEdit: boolean;
  onlyMyGoalGroups: boolean;
  status: string[];
  partners: number[];
  goalGroups: number[];
  isEditing: boolean;
  term: number;
}

const FiltersContext = React.createContext<FiltersContextType>({
  timePeriodFilters: {
    DailyDialsTalktime: 'this calendar week',
    HoursToFirstCall: 'this calendar week',
    SevenDayEngagementRate: 'last fourteen days',
    SevenDayEngagedToStartedAppRate: 'last fourteen days',
    EnrollmentFunnelPacing: 'this recruitment cycle',
    CoachResolutionTimes: 'last seven days',
    CoachReportedStudentRisk: 'last seven days',
    CoachResponseTimes: 'last seven days',
    StudentsAwaitingCoachOutreach: 'current',
    StudentAcademicRiskIndicators: 'current',
    PaidMediaSpendPacing: 'last thirty days',
    ProspectPacing: 'this marketing period',
    CostPerProspect: 'this marketing period',
  },
  sortByFilters: {
    DailyDialsTalktime: '2',
    HoursToFirstCall: '1',
    SevenDayEngagementRate: '1',
    SevenDayEngagedToStartedAppRate: '1',
    EnrollmentFunnelPacing: '1',
    CoachResolutionTimes: '1',
    StudentsAwaitingCoachOutreach: '1',
    StudentAcademicRiskIndicators: '1',
    PaidMediaSpendPacing: '1',
    ProspectPacing: '1',
    CostPerProspect: '1',
    CoachReportedStudentRisk: '1',
    CoachResponseTimes: '1',
  },
  termsFilter: 0,
  keyDatesFilter: {
    onlyDataICanEdit: false,
    onlyMyPrograms: false,
    status: ['Changes', 'Draft', 'Published', 'No Data'],
    partners: [],
    programs: [],
    isEditing: false,
    term: 0,
  },
  goalsFilter: {
    onlyDataICanEdit: false,
    onlyMyPrograms: false,
    status: ['Changes', 'Draft', 'Published', 'No Data'],
    partners: [],
    programs: [],
    isEditing: false,
    term: 0,
  },
  goalGroupKeyDatesFilter: {
    onlyDataICanEdit: false,
    onlyMyGoalGroups: false,
    status: ['Changes', 'Draft', 'Published', 'No Data'],
    partners: [],
    goalGroups: [],
    isEditing: false,
    term: 0,
  },
  goalGroupGoalsFilter: {
    onlyDataICanEdit: false,
    onlyMyGoalGroups: false,
    status: ['Changes', 'Draft', 'Published', 'No Data'],
    partners: [],
    goalGroups: [],
    isEditing: false,
    term: 0,
  },
  updateTimePeriodFilters: () => {},
  updateSortByFilters: () => {},
  updateTermFilter: () => {},
  updateKeyDatesFilter: () => {},
  updateGoalsFilter: () => {},
  updateGoalGroupKeyDatesFilter: () => {},
  updateGoalGroupGoalsFilter: () => {},
});

export default FiltersContext;

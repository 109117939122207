import React, { useEffect, useState } from 'react';
import FiltersContext, { TimePeriodFilters, IKeyDatesGoalsFilters, IGoalGroupKeyDatesGoalsFilters } from './context';
import { useSetState } from 'hooks/useSetState';

type ProviderProps = {
  children?: React.ReactNode;
};

export const getUserRole = () => {
  const userObject: string[] =
    JSON.parse(window.localStorage.getItem('user') || '{}')?.groups ?? [];
  const type = getFilterTypeFromUserRole(userObject);
  return type;
};

const getInitialSortByFilter = () => {
  const type = getUserRole();
  return `{
    "DailyDialsTalktime": "2",
    "HoursToFirstCall": "${type}",
    "SevenDayEngagementRate": "${type}",
    "SevenDayEngagedToStartedAppRate": "${type}",
    "EnrollmentFunnelPacing": "${type}",
    "CoachResolutionTimes": "${type}",
    "StudentsAwaitingCoachOutreach": "${type}",
    "StudentAcademicRiskIndicators": "${type}",
    "CoachReportedStudentRisk": "${type}",
    "CoachResponseTimes": "${type}",
    "PaidMediaSpendPacing": "1",
    "ProspectPacing": "1",
    "CostPerProspect": "1"
  }`;
};

const getFilterTypeFromUserRole = (arrayOfRoles: string[]) => {
  const programAsDefaultFilter = [
    'SuperAdmin',
    'MarketingUser',
    'MarketingAdmin',
    'OperationsUser',
    'SolutionsAdmin',
    'OperationsAdmin',
  ];
  //? Leave this variable only to check the other possible roles
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const enrollmentOrSSCAsDefaultFilter = [
    'EnrollmentAdmin',
    'EnrollmentUser',
    'StudentSupport',
    'StudentSupportAdmin',
  ];
  const programIsDefaultFilter = arrayOfRoles.some((role) => programAsDefaultFilter.includes(role));
  return programIsDefaultFilter ? '1' : '2';
};

export const initialTimeperiodFilters = `{
  "DailyDialsTalktime": "this calendar week",
  "HoursToFirstCall": "this calendar week",
  "SevenDayEngagementRate": "last fourteen days",
  "SevenDayEngagedToStartedAppRate": "last fourteen days",
  "EnrollmentFunnelPacing": "this recruitment cycle",
  "CoachResolutionTimes": "last seven days",
  "CoachReportedStudentRisk": "last seven days",
  "CoachResponseTimes": "last seven days",
  "StudentsAwaitingCoachOutreach": "current",
  "StudentAcademicRiskIndicators": "current",
  "PaidMediaSpendPacing": "last thirty days",
  "ProspectPacing": "this marketing period",
  "CostPerProspect": "this marketing period"
}`;

export const initialTermsFilter = '0';
export const initialKeyDatesGoalsFilter = `{
  "onlyDataICanEdit": false,
  "onlyMyPrograms": false,
  "status": ["Changes", "Draft", "Published", "No Data"],
  "partners": [],
  "programs": [],
  "isEditing": false,
  "term": 0
}`;

export const initialGoalGroupKeyDatesGoalsFilter = `{
  "onlyDataICanEdit": false,
  "onlyMyGoalGroups": false,
  "status": ["Changes", "Draft", "Published", "No Data"],
  "partners": [],
  "goalGroups": [],
  "isEditing": false,
  "term": 0
}`;

function FiltersProvider(props: ProviderProps) {
  const [timePeriodFilters, setTimePeriodFilters] = useState<TimePeriodFilters>(
    JSON.parse(window.localStorage.getItem('TimePeriod-filters') || initialTimeperiodFilters),
  );

  const [sortByFilters, setSortByFilters] = useState<TimePeriodFilters>(
    /*
      1: Program Averages
      2: Enrollment Advisors / Support Coaches (only for CoachReportedStudentRisk)
    */
    JSON.parse(getInitialSortByFilter()),
  );

  const [termsFilter, setTermsFilter] = useState<number>(
    JSON.parse(window.localStorage.getItem('term-dropdown-filter') || initialTermsFilter),
  );

  const [keyDatesFilter, setKeyDatesFilter] = useSetState<IKeyDatesGoalsFilters>(
    JSON.parse(window.localStorage.getItem('key-dates-dropdown-filters') || initialKeyDatesGoalsFilter),
  );

  const [goalsFilter, setGoalsFilter] = useSetState<IKeyDatesGoalsFilters>(
    JSON.parse(window.localStorage.getItem('goals-dropdown-filters') || initialKeyDatesGoalsFilter),
  );

  const [goalGroupKeyDatesFilter, setGoalGroupKeyDatesFilter] = useSetState<IGoalGroupKeyDatesGoalsFilters>(
    JSON.parse(window.localStorage.getItem('goal-group-key-dates-dropdown-filters') || initialGoalGroupKeyDatesGoalsFilter),
  );

  const [goalGroupGoalsFilter, setGoalGroupGoalsFilter] = useSetState<IGoalGroupKeyDatesGoalsFilters>(
    JSON.parse(window.localStorage.getItem('goal-group-goals-dropdown-filters') || initialGoalGroupKeyDatesGoalsFilter),
  );

  useEffect(() => {
    window.localStorage.setItem('TimePeriod-filters', JSON.stringify(timePeriodFilters));
  }, [timePeriodFilters]);

  useEffect(() => {
    window.localStorage.setItem('SortBy-filters', JSON.stringify(sortByFilters));
  }, [sortByFilters]);

  useEffect(() => {
    window.localStorage.setItem('term-dropdown-filter', JSON.stringify(termsFilter));
  }, [termsFilter]);

  useEffect(() => {
    if (keyDatesFilter)
      window.localStorage.setItem('key-dates-dropdown-filters', JSON.stringify(keyDatesFilter));
  }, [keyDatesFilter]);

  useEffect(() => {
    if (goalsFilter)
      window.localStorage.setItem('goals-dropdown-filters', JSON.stringify(goalsFilter));
  }, [goalsFilter]);

  useEffect(() => {
    if (goalGroupKeyDatesFilter)
      window.localStorage.setItem('goal-group-key-dates-dropdown-filters', JSON.stringify(goalGroupKeyDatesFilter));
  }, [goalGroupKeyDatesFilter]);

  useEffect(() => {
    if (goalGroupGoalsFilter)
      window.localStorage.setItem('goal-group-goals-dropdown-filters', JSON.stringify(goalGroupGoalsFilter));
  }, [goalGroupGoalsFilter]);

  const updateTimePeriodFilters = (reportKey: string, newTimeValue: string) => {
    setTimePeriodFilters({ ...timePeriodFilters, [reportKey]: newTimeValue });
  };

  const updateSortByFilters = (reportKey: string, newTimeValue: string) => {
    setSortByFilters({ ...sortByFilters, [reportKey]: newTimeValue });
  };

  const updateTermFilter = (value: number) => {
    setTermsFilter(value);
  };

  const updateKeyDatesFilter = (key: string, value: string[] | number[] | number | boolean) => {
    setKeyDatesFilter({ [key]: value });
  };

  const updateGoalsFilter = (key: string, value: string[] | number[] | number | boolean) => {
    setGoalsFilter({ [key]: value });
  };

  const updateGoalGroupKeyDatesFilter = (key: string, value: string[] | number[] | number | boolean) => {
    setGoalGroupKeyDatesFilter({ [key]: value });
  };

  const updateGoalGroupGoalsFilter = (key: string, value: string[] | number[] | number | boolean) => {
    setGoalGroupGoalsFilter({ [key]: value });
  };

  return (
    <FiltersContext.Provider
      value={{
        timePeriodFilters,
        sortByFilters,
        termsFilter,
        keyDatesFilter,
        goalsFilter,
        goalGroupKeyDatesFilter,
        goalGroupGoalsFilter,
        updateTimePeriodFilters,
        updateSortByFilters,
        updateTermFilter,
        updateKeyDatesFilter,
        updateGoalsFilter,
        updateGoalGroupKeyDatesFilter,
        updateGoalGroupGoalsFilter,
      }}
    >
      {props.children}
    </FiltersContext.Provider>
  );
}

export default FiltersProvider;
